/*================================================
 Power-off page styles 
=================================================*/

.power-off {
  padding-top: 65px;
  background-color: #FFFFFF;
  
  h4 {
    font-size: 20px;
  }

  .tab-inner {
    display: flex;
    flex-direction: column;

    a {
      text-align: left;
      margin-top: 20px;
    }
  }
  
  .react-tabs {
    max-width: 800px;
    margin: 0 auto;

    img {
      object-fit: contain;
      max-width: 800px;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 30px;
    color: var(--fontColor);
    font-family: var(--fontFamilyBold);
  }

  &__top {
    font-weight: 400;
    font-size: var(--fontSize);
    line-height: 27px;
  }

  &__group {
    text-align: center;
    font-weight: 400;
    font-size: var(--fontSize);
    line-height: 27px;
    margin-bottom: 60px;
  }

  .date-synk {
    background-color: var(--greyLightMore);
    border-left: 2px solid var(--greenMain);
    padding: 2rem 1rem;
    font-size: 17.5px;
    color: rgb(108, 117, 125);
    margin-bottom: 80px;
    .red {
      color: #e74c3c;
    }
  }

  .date-synk.grafics {
    margin-top: 20px;
  }

  .power-a {
    display: inline-block;
    color: var(--whiteLOE);
    padding: 20px 30px;
    border-radius: 4px;
    background-color: var(--greenDark);
    border: none;
    font-weight: 500;
    font-size: var(--fontSize);
    line-height: 21px;
    cursor: pointer;
    margin: 0 0 60px 0;
    min-width: 160px;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      background-color: var(--greenHover);
    }
  }

  .react-tabs {
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    padding: 30px;
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 4%;
  }

  .react-tabs__tab {
    font-weight: 600;
    border: none;
    user-select: none;

    &:focus:after {
      background: none;
    }
  }

  .react-tabs__tab--selected {
    border-radius: 0 0 0 0;
    background: var(--grayBack);
    border-bottom: 3px solid var(--greenMain);
  }

  .Toastify__toast-container--top-left {
    top: 1em;
    left: -24em;
  }

  table {
    th {
      padding: 20px;
      background-color: #000;
      color: var(--whiteLOE);
      font-weight: 400;
      text-align: center;
    }
    td {
      font-size: var(--fontSize);
    }
  }
}

/*-----------asd------------------*/

.card {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  line-height: 27px !important;
  color: var(--fontColor) !important;
  }
  
  .title-grf {
  padding-top: 82px !important;
  font-family: var(--fontFamily) !important;
  }
  
  .card h1, .card h5, .card input, .card button, .card label, .card a {
  font-family: var(--fontFamily) !important;
  }
  
  .tabs {
  max-width: 1000px;
  margin: auto;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  }
  
  .tabs * {
  box-sizing: border-box;
  }
  
  .tabs__bar-wrap {
  position: relative;
  }
  
  .tabs__bar-wrap.has-left-overflow::before {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 30px;
  pointer-events: none;
  }
  
  .tabs__bar-wrap.has-right-overflow::after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 30px;
  pointer-events: none;
  }
  
  .tabs__bar-wrap.has-left-overflow::before {
  left: 0;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 100%);
  }
  
  .tabs__bar-wrap.has-right-overflow::after {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 100%);
  }
  
  .tabs__bar {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 3px 3px 0 0;
  box-shadow: inset 0 -5px 5px -3px rgba(0, 0, 0, 0.05);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  }
  
  @media (min-width: 480px) {
  .tabs__bar {
    min-width: auto;
    overflow: hidden;
  }
  }
  
  .tabs__line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #4285f4;
  will-change: transform;
  transform-origin: left;
  }
  
  .tabs__controls {
  flex: 1 0 auto;
  min-width: 150px;
  display: block;
  padding: 20px 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  }
  
  .tabs__controls.is-active {
  color: #4285f4;
  }
  
  @media (min-width: 480px) {
  .tabs__controls {
    min-width: auto;
  }
  }
  
  .tabs__controls:hover {
  background-color: rgba(0, 0, 0, 0.03);
  }
  
  .tabs__controls:active {
  -webkit-user-select: none;
          user-select: none;
  }
  
  .tabs__content {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: calc(1000px * 3);
  overflow-y: hidden;
  background-color: #fff;
  will-change: transform, height;
  }
  
  .tabs__content.has-grab {
  cursor: grabbing;
  }
  
  .tabs__section {
  padding: 30px;
  width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100%;
    height: 100%;
  }
  }
  
  .tabs__section a {
  padding-top: 15px;
  text-align: left;
  color: #4285f4;
  }
  
  .tabs__section img {
  max-width: 100%;
  }
  
  @media screen and (max-width: 992px) {
  .tabs__section {
    padding: 8px;
  }
  }
  
  /*--------------------------------------------*/

.power-btn {
  color: var(--whiteLOE);
  padding: 20px 30px;
  border-radius: 4px;
  background-color: var(--greenDark);
  border: none;
  font-weight: 500;
  font-size: var(--fontSize);
  line-height: 21px;
  cursor: pointer;
  margin: 44px 0 60px 0;
  min-width: 160px;
  transition: all 0.3s;

  &:hover {
    background-color: var(--greenHover);
  }
}

.arrow-inner {
  display: none;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 900;
  text-decoration: none;
  user-select: none;
}

.arrow-inner a {
  text-decoration: none;
}

// select styles

.css-b62m3t-container,
.css-16xfy0z-control {
  margin-bottom: 30px;
  height: 58px;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-16xfy0z-control {
  cursor: pointer;
    font-size: var(--fontSize);
    line-height: 27px;
    border: 1px solid #C4CBD2;
    border-radius: 4px;
    padding-left: 10px;
    width: 400px;
    height: 58px;
    box-sizing: border-box;
}

.css-t3ipsp-control {
  border-color: var(--greyLight) !important;
  box-shadow: 0 0 5px rgba(196,203,210, 1) !important;
}

.css-d9ann2-control {
  border-color: var(--greyLight) !important;
  box-shadow: 0 0 5px rgba(196,203,210, 1) !important;
}

.css-1ekzhq6-control:hover {
  border-color: #e74c3c !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}

//----------------------------------/

//----------paginaton------------------------/

.page-link {
  color: var(--fontColor);
}

.active>.page-link, .page-link.active  {
  background-color: var(--greyLight);
  border-color: var(--greyLight);
}

//----------------------------------/


.transp-btn {
  color: var(--fontColor);
  background-color: transparent;
  padding: 20px 20px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #576676;
  font-weight: 700;
  font-size: var(--fontSmallSize);
  line-height: 21px;
  cursor: pointer;
  min-width: 160px;
  display: inline-block;
  transition: all 0.3s;
  text-align: center;

  &:hover {
    color: #333D47;
    text-decoration: none;
    border-color: var(--greenDark);
    background-color: var(--greenLight3);
  }
}

.attention {
  margin: 60px 0;

  P {
    // font-weight: 700;
    font-size: var(--fontLinksSize);
    line-height: 27px;
  }
  ul {
    padding-left: 25px;
  }
  li {
    font-weight: 400;
    font-size: var(--fontSize);
    line-height: 27px
  }
}

.search-off {
  padding: 30px 0;

  &__table {
    overflow-x: auto;
    margin-top: 100px;
    padding-bottom: 10px;
  }

  .off-msg {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 0;
  }

  form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
  }

  label {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: var(--fontSize);
    line-height: 27px;
  }

  .power-btn {
    margin: 0 auto;
  }
}

.input-search {
  width: 100%;
  margin-bottom: 24px;
  font-size: var(--fontSize);
  line-height: 27px;
  border: 1px solid #C4CBD2;
  border-radius: 4px;
  padding: 14px 14px 14px 18px;
  max-width: 426px;
  box-sizing: border-box;

  &::placeholder {
    color: rgb(30, 30, 30);
    font-weight: 500;
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(196,203,210, 1);
  }
}

.group-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  img {
    max-width: 100%;
    height: 100%;
  }
}

.group-commands {
  text-align: left;
  margin-bottom: 20px;

  p {
    font-size: 18px;
  }
}

.or {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;

  p {
    padding: 0 5px;
    margin-bottom: 0;
  }
}

.line {
  width: 180px;
  height: 1px;
  background-color: var(--greenMain);
}

.report {
  margin-bottom: 70px;
  text-align: center;
  display: flex;
  gap: 50px;

  .power-btn {
    background-color: transparent;
    color: var(--fontColor);
    border: 1px solid #576676;
    margin: 0 auto;

    &:hover {
      color: var(--whiteLOE);
      border-color: var(--greenHover);
    }
  }
}

.group-mobile {
  max-width: 365px;
  margin: 0 auto;
}

.slide-inner {
  p {
    margin-bottom: 25px;
  }
}

.swiper-button-next, .swiper-button-prev {
  color: var(--greenMain) !important;
  top: 25px !important;
} 

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: var(--fontSmallSize) !important;
  font-weight: 700;
}

/*================================================
  GpvSearch page  
=================================================*/

.gpv-search {
  padding-top: 140px;
  background-color: var(--grayBack);

  h1 {
    text-align: center;
  }

  &-inner {
    border: 1px solid #C4CBD2;
    max-width: 500px;
    margin: 30px auto 70px auto;
    background-color: var(--whiteLOE);
    border-radius: 10px;
    // overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      max-width: 500px;
      padding-bottom: 10px;
      border-radius: 10px;
      object-fit: cover;
      object-position: top;
    }

    h5 {
      span {
        display: block;
        padding-top: 10px;
        font-weight: 700;
        font-size: var(--fontSize);
      }
    }
  }

  .gpv-show {
    padding-bottom: 10px;
    
    &-inner {
      overflow: hidden;
    }

    form {
      padding: 0 10px;
    }
    
    img {
      width: 100%;
      max-width: 500px;
      padding-bottom: 10px;
      object-fit: contain;
      border-radius: 0px;
    }

    a {
      display: inline-block;
      padding-left: 10px;
      margin-bottom: 15px;
      color: var(--linksColor);
    }

    .input-search {
      margin-bottom: 0;
    }
    .power-btn{
      padding: 10px 30px;
      margin: 15px 0 10px 0;
    }
  }

  .css-b62m3t-container, h5 {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  h6 {
    padding: 0 10px;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .css-16xfy0z-control {
    width: 100%;
  }
}

/*================================================
 Send-off page styles 
=================================================*/

.gpv-search.off {

  .gpv-search-inner {
    padding-bottom: 20px;
    h5 {
      padding-left: 15px;
    }
    p {
      padding: 0 15px;
    }
    img {
      max-height: 450px;
    }

    form {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      max-width: 426px;
      margin: 0 auto;
    }

    .power-btn {
      margin: 0;
    }

    .form-title {
      padding-top: 15px;
      text-align: center;
    }
  }

  .off-button-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;

    button {
      background-color: #0d6efd;
      color: #fff;
      text-transform: uppercase;
      padding: 20px;
      border: none;
      border-radius: 5px;

      &:hover {
        background-color: #2b80ff;
      }
    }
  }
}

/*================================================
 Grafik page styles 
=================================================*/

.period-inner {
  display: flex;
  max-width: 400px;
  gap: 30px;

  input {
    margin-top: 5px;
    width: 100%;
    margin-bottom: 24px;
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    line-height: 27px;
    border: 1px solid #C4CBD2;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;

    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(196,203,210, 1);
    }
  }

  /* placeholder text style */
    input[type="date"]::-webkit-datetime-edit-text,
    input[type="date"]::-webkit-datetime-edit-month-field,
    input[type="date"]::-webkit-datetime-edit-day-field,
    input[type="date"]::-webkit-datetime-edit-year-field {
      opacity: 0.5;
    }

    /* regular text style */
    input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
    input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
    input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
    input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
      opacity: 1;
    }
}


/*================================================
 Discrepancy page styles 
=================================================*/

.discrepancy {
  $color1: #f4f4f4;
  $color2: var(--greenDark);
  margin-top: 95px;
  
  h4 {
    margin-bottom: 10px;
  }

  &__top,
  &__mid,
  &__bottom {
    background-color: #fff;
    padding: 25px 15px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem;
    margin-bottom: 50px; 
  }

  &__sub {
    margin-bottom: 20px;
  }

  .power-btn {
    margin: 20px 0 0 0;
  }
    
  .radio {
    margin: 0.5rem 0.5rem 1rem 0;

    label {
      cursor: pointer;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: $color1;
          border-radius: 100%;
          border: 1px solid darken($color1, 25%);
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.1em;
          margin-right: 1em; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: $color2;
            box-shadow: inset 0 0 0 4px $color1;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: $color2;
          }
        }
      }
      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px $color1;
            border-color: darken($color1, 25%);
            background: darken($color1, 25%);
          }
        }
      }
      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
}


/*================================================
 GrafikArhiv page styles 
=================================================*/

.arhiv-planned {
  margin-top: 50px;

  a {
    color: var(--linksColor);
  }
  p {
    text-align: center;
  }

  .pagination {
    padding-top: 30px;
  }
}


/*================================================
  Home page media 
=================================================*/


@media screen and (max-width: 1200px) {
  .power-off {
    .Toastify__toast-container--top-left {
      top: 22em;
      left: -275px;
    }
  }

  // .power-off, .gpv-search {
  //   padding-top: 120px;
  // }

  .gpv-search-inner {
    margin: 30px auto 50px auto;
  }
}

@media screen and (max-width: 1025px) {
  .power-off {
    .container {
      padding: 0 20px;
    }
    .Toastify__toast-container--top-left {
      top: 28em;
      left: 0;
      max-width: 400px;
      width: 100%;
    }
  }
  .report {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1025px) {
  .arrow-inner {
    display: flex;
  }

  .search-off__table {
    margin-top: 0;
  }
}

@media screen and (max-width: 575px) { 
  .power-off {
    h4 {
      font-size: var(--fontLinksSize);
    }
  }

  .power-off__top,
  .power-off__group {
    font-size: var(--fontSmallSize);
    line-height: 21px;
  }
  .power-off__title {
    font-size: var(--fontLinksSize);
    line-height: 27px;
  }
  .power-off .react-tabs__tab-list {
    gap: 10px 30px;
  }
}

@media screen and (max-width: 450px) { 
  .css-13cymwt-control,
  .css-t3ipsp-control,
  .css-16xfy0z-control,
  .css-i6oula-control,
  .css-1ekzhq6-control,
  .css-d9ann2-control {
    width: 100% !important;
    min-width: 100%;
  }
  .css-b62m3t-container,
  .css-3iigni-container {
    width: 100%;
  }
  .css-1nmdiq5-menu {
    width: 95% !important;
  } 

  .period-inner {
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 30px;

    input {
      max-width: 100%;
      margin-bottom: 0;
    }

    &__field {
      width: 100%;
      margin-bottom: 30px;
    }
  }

}